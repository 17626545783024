import { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment'
import { POST_ADD_SOLD_ITEMS, POST_CLOSE_ORDER, POST_SEND_MESSAGE, POST_START_ORDER, POST_UPDATE_ORDER } from '../services/orderService';

const CartContext = createContext();

const orderInitialState = {
  orderId: null,
  deliveryDay: null,
  priceListCode: null,
  routeName: null,
  totalPriceWithoutDiscount: null,
  update: {
    orderId: null,
    products: [],
    totalDiscount: null,
    totalPayment: null,
    totalPrice: null,
    totalTaxes: null,
    Total: null,
    SubTotal: null,
    DiscountFirstPurchase: null,
    DiscountProduct: null,
  }
}


export const CartContextProvider = ({ children }) => {
  const [chart, setChart] = useState({
    products: [],
    checkingProducts: [],
    orderConfirmed: false,
    orderError: false,
    progress: 0
  });

  const [stepper, setStepper] = useState(0)

  const [loadingOrder, setLoadingOrder] = useState(false)

  const [order, setOrder] = useState(orderInitialState)

  const { products } = chart;

  const setProgress = (newProgress) => {
    setChart({ ...chart, progress: newProgress });
  };

  const resetStepper = () => {
    setStepper(0)
  }

  const nextStepper = () => {
    setStepper(stepper + 1)
  }

  const backStepper = () => {
    setStepper(stepper - 1)
  }
  
  const addProductToChartAction = (product, amount) => {
    let exists = products.find((prod) => prod.product.code === product.code);
    let newProducts = products;
    if (exists) {
      newProducts = products.map((prod) => (prod.product.code === product.code ? { product: prod.product, amount: prod.amount + 1, promotionChecked: prod.promotionChecked } : prod));
    } else {
      newProducts.push({ product: { ...product }, amount: 1, promotionChecked: product.promotions.length > 0 });
      toast.success(<p style={{ fontFamily: 'AmsiPro'}}>Se añadió <span style={{ fontWeight:'700'}}>{product.name}</span> correctamente!</p>);
    }

    setChart((st) => ({
      ...st,
      products: newProducts,
    }));

    localStorage.setItem('chartProducts', JSON.stringify(newProducts));
  };


  const updateProductToChartAction = (product, amount, discountPrice = 0) => {
    let exists = products.find((prod) => prod.product.code === product.code);
    let newProducts = products;
    if (exists) {
      newProducts = products.map((prod) => (prod.product.code === product.code ? { product: prod.product,discountPrice, amount: amount === '' ? amount : Number(amount), promotionChecked: prod.promotionChecked } : prod));
    } else {
      newProducts.push({ product: { ...product } , discountPrice, amount: amount, promotionChecked: product?.promotions?.length > 0 });
    }

    setChart((st) => ({
      ...st,
      products: newProducts,
    }));
    localStorage.setItem('chartProducts', JSON.stringify(newProducts));
  };

  const RefreshProductToChartAction = (product) => {
    //console.log('update cart:', products);
    //console.log('Product2:', product);

    products.forEach((item) => {
      console.log('item:', item);
      let exists = product.find((prod) => prod.code === item.product.code);
      item.discountPrice = exists.salePrice?? 0;
      item.product = exists;
      console.log('ex:', exists);

      // if (item.product.code === product.code) {
      //   item.amount = amount;
      // }
    });

    //setChart({ ...chart, products: chartProducts });
    setChart((st) => ({
      ...st,
      products: products,
    }));
    localStorage.setItem('chartProducts', JSON.stringify(products));

  };


  const deleteProductFromChartAction = async (product) => {
    try {
      // console.log('deleteProductFromChartAction - chart:', getState().chart)
      // console.log('deleteProductFromChartAction - product.code: ', product.code)

      let newProducts = chart.products.filter((item) => item.product.code !== product.code);

      // dispatch({
      // 	type: DELETE_EXISTING_PRODUCT,
      // 	payload: {
      // 		chartProducts: newProducts,
      // 	},
      // });
      setChart({ ...chart, products: newProducts });

      localStorage.setItem('chartProducts', JSON.stringify(newProducts));
    } catch (error) {
      console.log(error);
    }
  };

  const substractProductToChartAction = async (product, amount) => {
    try {
      // console.log('substractProductToChartAction - chart: ', getState().chart)
      // console.log('substractProductToChartAction - product.code: ', product.code)
      // console.log('substractProductToChartAction - amount: ', amount)

      let chartProducts = chart.products.slice();

      chartProducts.forEach((item) => {
        if (item.product.code === product.code) {
          item.amount = amount;
        }
      });

      // console.log('substractProductToChartAction - chart modified: ', chartProducts)

      // dispatch({
      // 	type: SUBSTRACT_EXISTING_PRODUCT_EXITO,
      // 	payload: { chartProducts: chartProducts },
      // });
      setChart({ ...chart, products: chartProducts });

      localStorage.setItem('chartProducts', JSON.stringify(chartProducts));
    } catch (error) {
      console.log(error);
    }
  };

  const handleStartOrderAndUpdateOrder = async (user, totalPrice) => {
    try {
      setLoadingOrder(true)
      console.log('sip1: ', user);
      //AQUI CAMBIAR LA DIRECCION DEL CLIENTE
      const startOrderParameters = {
        orderId: '',
        companyId: '1',
        clientId: user.odooClientCode,
        addressId: user.addressId,
        dayToDelivery: user.dayToDelivery,
        dayOfWeek: user.dayOfWeek,
        discountProducts : totalPrice.discountProducts,
        DiscountFirstPurchase : totalPrice.discountFirstPurchase,
        deliveryAdress: {
          address: '',
          city: '',
          country: '',
          district: '',
          latitude: '',
          longitude: '',
          number: '',
          street: '',
        },
        date: moment().tz('America/Lima').format('DD/MM/YYYY').toString(),
        sellingConditionId: '001',
        // priceListId: user.deliveryAddresses.filter((item) => item.code === user.addressId)[0].priceListCode,
        // storageId: user.storageId,
        // sellerId: user.deliveryAddresses.filter((item) => item.code === user.addressId)[0].sellerCode,
        // routeId: user.deliveryAddresses.filter((item) => item.code === user.addressId)[0].routeId,
        // latitude: '' + user.deliveryAddresses.filter((item) => item.code === user.addressId)[0].latitude,
        // longitude: '' + user.deliveryAddresses.filter((item) => item.code === user.addressId)[0].longitude,
        status: '0',
        clientMobilePhone: user.mobilePhone,
        routes: user.routes,
      }
  
      

      
      let totalPriceWithoutDiscount = 0;

      const products = chart.products.map(item => {

        totalPriceWithoutDiscount += parseFloat((item.amount * item.product.salePrice).toFixed(1));

        return {
          productId: item.product.code,
          taxId: item.product.taxId ?? 0,
          amount: item.amount,
          type: 'N',
          shortName: item.product.shortName,
          unitPrice: item.product.salePrice,
          finalPrice: Number((item.amount * item.product.salePrice).toFixed(1)),
        }
      })

      // const updateOrderParameters = {
      //   companyId: '1',
      //   orderId: startOrderResponse.orderId,
      //   products: products,
      //   clientMobilePhone: user.mobilePhone,
      // }



      startOrderParameters.products = products;
      const startOrderResponse = await POST_START_ORDER(startOrderParameters);


      // const updateOrderResponse = await POST_UPDATE_ORDER(updateOrderParameters)

      setOrder({
        ...startOrderResponse,
        totalPriceWithoutDiscount,
        update: {
          ...startOrderResponse
        }
      })

      setLoadingOrder(false)
      
    } catch (error) { 
      setLoadingOrder(false)
      resetStepper();
      console.error(error);
    }
  }

  const handleCloseOrder = async (user) => {
    try {
      
      setLoadingOrder(true);

      const closeOrderParameters = {
        companyId: '1',
        orderId: order.orderId,
        confirmFlag: '1',
        clientMobilePhone: user.mobilePhone
      }

      await POST_CLOSE_ORDER(closeOrderParameters);
     

      const updateOrderInfo = { ...order.update };

      updateOrderInfo.deliveryDay = order.deliveryDay;
      updateOrderInfo.deliveryHour = user.schedule;
      updateOrderInfo.routeName = order.routeName;
      updateOrderInfo.priceListCode = order.priceListCode;
    

      const sendMessageParameters = {
        companyId: '1',
        orderId: order.orderId,
        confirmFlag: '1',
        clientMobilePhone: user.mobilePhone
      }

      //sendMessageParameters.products = products;
      await POST_SEND_MESSAGE(sendMessageParameters)
      
      setChart({ ...chart, products: [] });
      localStorage.removeItem('chartProducts');
      setLoadingOrder(false)
    } catch (error) {

      setLoadingOrder(false)
      resetStepper();
      console.error(error);
    }
  }
  
  

  return (
    <CartContext.Provider
      value={{
        chart,
        setChart,
        addProductToChartAction,
        deleteProductFromChartAction,
        substractProductToChartAction,
        updateProductToChartAction,
        RefreshProductToChartAction,
        setProgress,
        stepper,
        setStepper,
        resetStepper,
        nextStepper,
        backStepper,
        loadingOrder,
        handleStartOrderAndUpdateOrder,
        handleCloseOrder,
        order
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
